<!--添加题目-->
<template>
  <div class="main">
     <div style="font-size: 26px; font-weight: bold;display:flex"><div style="color:#C8C8C8;cursor: pointer;" @click='goback'>&lt;题库管理</div><div>/添加题目</div></div>
    <div class="title">
      <div class="titleleft">
        <div style="align-items: center">
          <div class="line"></div>
          <div>题目设置</div>
        </div>
        <el-form label-position="right" style="margin-top: 40px">
          <el-form-item label="题目类型：">
            <el-select v-model="value" placeholder="请选择" >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="品牌：">
            <el-select v-model="brandIdList" multiple  placeholder="请选择">
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="考试等级：">
            <el-select v-model="levelId" placeholder="请选择">
              <el-option
                v-for="item in levelLists"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="题干：">
            <el-input
              type="textarea"
              :rows="5"
              v-model="textarea"
              :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
            >
            </el-input>
          </el-form-item>
           <el-form-item label="操作：" v-if='value==3'>
             <el-button @click="insterLine">插入下划线</el-button>
          </el-form-item>
          <el-form-item label="参考答案：" v-if='value==4 || value==5'>
            <el-input
              
              type="textarea"
              :rows="5"
              v-model="textarea2"
              :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="flex-direction: column" v-if="value != 4 && value != 5">
        <div style="align-items: center">
          <div class="line"></div>
          <div style="display: flex; align-items: baseline">
            <div>答案设置</div>
            <div
              style="font-size: 14px; color: #bababa; margin-left: 11px"
              v-if="value == 1 || value == ''"
            >
              (选中则为答案，可多选)
            </div>
          </div>
        </div>
        <div style="flex-direction: column">
          <!-- <div style="display:flex;margin-top:26px;align-items:center">
                <el-radio v-model="radio" label="1" style="display:flex;align-items:center"><div style="margin:0 13px 0 27px">A</div><el-input v-model="inputA" placeholder="请填入选项内容" style="width:290px"></el-input></el-radio>
            </div>
            <div style="display:flex;margin-top:26px">
                <el-radio v-model="radio" label="2" style="display:flex;align-items:center"><div style="margin:0 13px 0 27px">B</div><el-input v-model="inputB" placeholder="请填入选项内容" style="width:290px"></el-input></el-radio>
            </div> -->
          <el-checkbox-group
            v-model="checkList"
            style="display: flex; flex-direction: column"
            v-if="value == 1 || value == ''"
          >
            <div
              style="display: flex; margin-top: 26px; align-items: center"
              v-for="(item, index) of selectData"
              :key="index"
            >
              <!-- <el-radio
              v-model="radio"
              :label="item.label"
              style="display: flex; align-items: center"
              ><div style="margin: 0 13px 0 27px">{{ item.name }}</div>
              <el-input
                v-model="item.value"
                placeholder="请填入选项内容"
                style="width: 290px"></el-input></el-radio>
                <img
              v-if="index + 1 == selectData.length && index > 2"
              src="../../images/reduce.png"
              alt=""
              style="width: 22px; height: 23px"
              @click='dele(index)'
            /> -->
              <el-checkbox
                :label="item.label"
                style="display: flex; align-items: center"
                ><div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                <el-input
                  v-model="item.value"
                  placeholder="请填入选项内容"
                  style="width: 290px"
                ></el-input
              ></el-checkbox>
              <img
                v-if="index + 1 == selectData.length && index > 2"
                src="../../images/reduce.png"
                alt=""
                style="width: 22px; height: 23px; margin-left: 10px"
                @click="dele(index)"
              />
            </div>
            <div
              style="margin-top: 12px; margin-left: 200px"
              
            >
              <img
              src="../../images/add.png"
              alt=""
              style="width: 22px; height: 23px"
              @click="add"
              v-if='selectData.length<6'
            />
            </div>
          </el-checkbox-group>

          <div
            style="display: flex; margin-top: 26px; flex-direction: column;font-weight: normal"
            v-for="(item, index) of selectData"
            v-if="value == 3"
            :key="index"
          >
            <!-- <el-radio
              v-model="radio"
              :label="item.label"
              style="display: flex; align-items: center"
              ><div style="margin: 0 13px 0 27px">{{ item.name }}</div>
              <el-input
                v-model="item.value"
                placeholder="请填入选项内容"
                style="width: 290px"></el-input></el-radio>
                <img
              v-if="index + 1 == selectData.length && index > 2"
              src="../../images/reduce.png"
              alt=""
              style="width: 22px; height: 23px"
              @click='dele(index)'
            /> -->
            <div style="align-items:center">
              <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
            <el-input
              v-model="item.value"
              placeholder="请填入选项内容"
              style="width: 290px"
            ></el-input>
            <img
              v-if="index + 1 == selectData.length && index > 0"
              src="../../images/reduce.png"
              alt=""
              style="width: 22px; height: 23px; margin-left: 10px"
              @click="dele(index)"
            />
            </div>
             <div
              style="margin-top: 12px; margin-left: 200px"
              v-if="index + 1 == selectData.length && index <5"
            >
              <img
              src="../../images/add.png"
              alt=""
              style="width: 22px; height: 23px"
              @click="add"
            />
            </div>
          </div>
          <div
            style="display: flex; margin-top: 26px; align-items: center"
            v-for="(item, index) of selectData"
            v-if="value== 2"
            :key="index"
          >
            <el-radio
              v-model="radio"
              :label="item.label"
              style="display: flex; align-items: center"
              ><div style="margin: 0 13px 0 27px">{{ item.name }}</div>
              <!-- <el-input
                v-model="item.value"
                placeholder="请填入选项内容"
                style="width: 290px"></el-input> -->
            </el-radio>
            <img
              v-if="index + 1 == selectData.length && index > 2"
              src="../../images/reduce.png"
              alt=""
              style="width: 22px; height: 23px"
              @click="dele(index)"
            />

            <!-- <div
              style="margin-top: 12px; margin-left: 200px"
              v-if="selectData.length < 6"
            >
              <img
              src="../../images/add.png"
              alt=""
              style="width: 22px; height: 23px"
              @click="add"
            />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <el-form label-position="right"  v-if='value==5' >
      <el-form-item label="操作：">
         <input  type="file" @change="uploadimg" accept='image/*' id='file' ref='file' style="color:#fff"/>
        
          <!-- <img width="100%" v-for='(item,index) in imglist' :src="item" alt=""  :key='index'/> -->
          
      </el-form-item>
      <el-form-item label='图片：' style="color:red" v-if='imglist.length>0'>
        <div style="display:flex">
              <div style="position:relative;width:200px"  v-for='(item,index) in imglist'  alt=""  :key='index'>
                <div style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);" ></div>
              <img :src="item" alt="" style="width: 200px"  />
              <img  @click="deleteImg(index)"  src="../../images/delete.png" alt="" style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px">
            </div>
              </div>
      </el-form-item>
    </el-form>
    <div style="display: flex">
      <el-button type="primary" style="margin: 0 auto 65px; width: 200px" @click='submit'
        >添加</el-button
      >
    </div>
  </div>
</template>

<script>
import {questiontypeList,levelList,qssaveOrUpdate,getCosCredit} from "../../apis/index";
var COS = require('cos-js-sdk-v5');
export default {
  data() {
    return {
      courseId:'',//课程id
      brandList:[
       
      ],
       brandIdList:'',
      imglist:[],
      textarea2:'',
      textarea:'',
      fileList:[],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      textarea: "",
      checkList: [],
      radio: "1",
      options: [
        {
          name:'选择题',
          id:1
        },
        {
          name:'判断题',
          id:2
        }
      ],//题目类型列表
      value: "",
      levelLists:[],//等级列表
      levelId:'',//等级值
      selectData: [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ],
    };
  },
  watch: {
    value(newdata, olddate) {
      console.log(newdata);
      if (newdata == 1) {
        this.selectData = [
          {
            label: "1",
            name: "A",
            value: "",
          },
          {
            label: "2",
            name: "B",
            value: "",
          },
          {
            label: "3",
            name: "C",
            value: "",
          },
        ];
      } else if (newdata == 3) {
        this.selectData = [
          {
            label: "1",
            name: "第1空",
            value: "",
          },
        ];
      } else if (newdata == 2) {
        this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
      }
    },
    checkList(){
      this.selectData.forEach(item=>{
        item.code=0
      })
    },
    radio(){
       this.selectData.forEach(item=>{
        item.code=0
      })
    },
  },
  created(){
    this.courseId=this.$route.query.courseId
    this.brandList=this.$store.state.brandList
    // console.log(this.$route.meta.keepAlive)
    // console.log(JSON.parse(this.$route.query.row))
    this.questype()
    this.leave()
  },

  methods: {
      deleteImg(index){//删除图片
        this.imglist.splice(index,1)
    },
    insterLine(){
      this.textarea=this.textarea+'____'      
    },
     uploadimg(e){//上传图片
      if(this.$refs['file'].files[0]){
        let filename = this.$refs['file'].files[0].name
        let selectFileobj = this.$refs['file'].files[0]
        // console.log(selectFileobj)
        this.upload(selectFileobj)
      }
    },
    upload(selectFileobj){
      let that=this
         let name = selectFileobj.name
       if(that.levelId==''){
          that.$message.error('请先选择等级')
          document.getElementById('file').value=''
          return 
        }
        let filename ='content/resource/LV'+that.levelId+'/'+'image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
           let url=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
           that.imglist.push(url)
           console.log(that.imglist)
          }
        });
        })
         
    },
    goback(){
      this.$router.go(-1)
    },
   async submit(){
      if(this.value==1){//选择题
        let selectData=this.selectData
      let checkList=this.checkList
      selectData.forEach(item=>{
        checkList.forEach(item2=>{
        if(item.label==item2){
          item.code=1
        }
      })
      })
      let str=''
    const titles=selectData.map(item=>{
        if(item.code){
          str+=item.code
        }else{
          str+=0
        }
        return item.name+item.value
      })
      let len=8-str.length
      for(var i=0;i<len;i++){
        str+='0'
      }
      const title=titles.join('&&&')
         const params={
            typeId:this.value,
            // levelId:this.levelId,
            content:this.textarea,
            rightKey:str,
            answer:title,
            courseId:this.courseId
            // brandIdList:this.brandIdList
      }
     const {data}=await qssaveOrUpdate(params)
      if(data.code==200){
        this.$message.success('操作成功')
        this.$router.go(-1)
      }else{
        this.$message.error(data.msg)
      }
      }else if(this.value==2){//判断题
        let selectData=this.selectData
        // let checkList=this.radio
        selectData.forEach(item=>{
       
        if(item.label==this.radio){
          item.code=1
        }
     
      })
      let str=''
    const titles=selectData.map(item=>{
        if(item.code){
          str+=item.code
        }else{
          str+=0
        }
        return item.name+item.value
      })
      let len=8-str.length
      for(var i=0;i<len;i++){
        str+='0'
      }
      const title=titles.join('&&&')
         const params={
            typeId:this.value,
            // levelId:this.levelId,
            content:this.textarea,
            rightKey:str,
            courseId:this.courseId
            // brandIdList:this.brandIdList
            // answer:title
      }
      // console.log(params)
     const {data}=await qssaveOrUpdate(params)
      if(data.code==200){
        this.$message.success('操作成功')
        this.$router.go(-1)
      }else{
        this.$message.error(data.msg)
      }
      }else if(this.value==3){//填空题
        let selectData=this.selectData
        let checkList=this.checkList
        selectData.forEach(item=>{
        checkList.forEach(item2=>{
        if(item.label==item2){
          item.code=1
        }
      })
      })
      let str=''
    const titles=selectData.map(item=>{
        if(item.code){
          str+=item.code
        }else{
          str+=0
        }
        // return item.name+item.value
        return item.value
      })
      let len=8-str.length
      for(var i=0;i<len;i++){
        str+='0'
      }
      const title=titles.join('&&&')
         const params={
            typeId:this.value,
            // levelId:this.levelId,
            content:this.textarea,
            // rightKey:str,
            answer:title,
            answerCount:this.selectData.length,
            brandIdList:this.brandIdList
      }
      const {data}=await qssaveOrUpdate(params)
      if(data.code==200){
        this.$message.success('操作成功')
        this.$router.go(-1)
      }else{
        this.$message.error(data.msg)
      }
      }else if(this.value==4){//主观题
        let selectData=this.selectData
        let checkList=this.checkList
         const params={
            typeId:this.value,
            levelId:this.levelId,
            content:this.textarea,
            answer:this.textarea2,
            brandIdList:this.brandIdList
      }
     const {data}=await qssaveOrUpdate(params)
      if(data.code==200){
        this.$message.success('操作成功')
        this.$router.go(-1)
      }else{
        this.$message.error(data.msg)
      }
      }else if(this.value==5){//看图说话
        let selectData=this.selectData
        let checkList=this.checkList
        let resourceUri=this.imglist.join('&&&')
         const params={
            typeId:this.value,
            levelId:this.levelId,
            content:this.textarea,
            answer:this.textarea2,
            resourceUri:resourceUri,
            brandIdList:this.brandIdList
      }
      // console.log(params)
     const {data}=await qssaveOrUpdate(params)
      if(data.code==200){
        this.$message.success('操作成功')
        this.$router.go(-1)
      }else{
        this.$message.error(data.msg)
      }
      }
     
    //   let selectData=this.selectData
    //   let checkList=this.checkList
    //   selectData.forEach(item=>{
    //     checkList.forEach(item2=>{
    //     if(item.label==item2){
    //       item.code=1
    //     }
    //   })
    //   })
    //   let str=''
    // const titles=selectData.map(item=>{
    //     if(item.code){
    //       str+=item.code
    //     }else{
    //       str+=0
    //     }
    //     return item.name+item.value
    //   })
    //   let len=8-str.length
    //   for(var i=0;i<len;i++){
    //     str+='0'
    //   }
    //   const title=titles.join('&&&')
    //      const params={
    //         typeId:this.value,
    //         levelId:this.levelId,
    //         content:this.textarea,
    //         rightKey:str,
    //         answer:title
    //   }
    //   console.log(params)
    },

       async leave(){//获取等级列表
     const {data}=await levelList({roleId:3})
     if(data.code==200){
       this.levelLists=data.data
     }else{
       this.$message.error(data.msg)
     }
    },
    //    async questype() {//题目类型列表
    //   const { data } = await questiontypeList();
    //   let qslist=data.data
    //   this.options = qslist;
    
    // },
    handChange(file,fileList){
      this.fileList=fileList
    },
    handleRemove(file) {
      let uid=file.uid
      let fileList=this.fileList
      fileList.map((item,index)=>{
        if(item.uid==uid){
          this.fileList.splice(index,1)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    add() {
      if(!this.value){
        this.$message.error('请先选择题目类型')
        return 
      }
      //添加
      if (this.value == 1) {
        let selectData = this.selectData;
        let res = selectData.some((item) => {
          return item.name == "D";
        });
        if (res) {
          let res = selectData.some((item) => {
            return item.name == "E";
          });
          if (res) {
            let res = selectData.some((item) => {
              return item.name == "E";
            });
            if (res) {
              let res = selectData.some((item) => {
                return item.name == "F";
              });
              if (!res) {
                this.selectData.push({
                  label: "6",
                  name: "F",
                  value: "",
                });
              }
            }
          } else {
            this.selectData.push({
              label: "5",
              name: "E",
              value: "",
            });

            console.log(this.selectData);
          }
        } else {
          this.selectData.push({
            label: "4",
            name: "D",
            value: "",
          });
          console.log(this.selectData);
        }
      } else if(this.value==3){
          let selectData = this.selectData;
          selectData.push({
            label: selectData.length+1,
            name: `第${selectData.length+1}空`,
            value: "",
          })
          this.selectData=selectData
          console.log(selectData)
      }
      // else if (this.value == 3) {
      //   let selectData = this.selectData;
      //   let res = selectData.some((item) => {
      //     return item.name == "第二空";
      //   });
      //   if (res) {
      //     let res = selectData.some((item) => {
      //       return item.name == "第三空";
      //     });
      //     if (res) {
      //       let res = selectData.some((item) => {
      //         return item.name == "第四空";
      //       });
      //       if (res) {
      //         let res = selectData.some((item) => {
      //           return item.name == "第五空";
      //         });
      //         if (res) {
      //           let res = selectData.some((item) => {
      //             return item.name == "第五空";
      //           });
      //           if (res) {
      //             this.selectData.push({
      //               label: "6",
      //               name: "第六空",
      //               value: "",
      //             });
      //           }
      //         } else {
      //           this.selectData.push({
      //             label: "5",
      //             name: "第五空",
      //             value: "",
      //           });
      //         }
      //       } else {
      //         this.selectData.push({
      //           label: "4",
      //           name: "第四空",
      //           value: "",
      //         });
      //       }
      //     } else {
      //       this.selectData.push({
      //         label: "3",
      //         name: "第三空",
      //         value: "",
      //       });

      //       console.log(this.selectData);
      //     }
      //   } else {
      //     this.selectData.push({
      //       label: "2",
      //       name: "第二空",
      //       value: "",
      //     });
      //     console.log(this.selectData);
      //   }
      // }
  
    },
    dele(ind) {
      this.selectData.splice(ind, 1);
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.title {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  div {
    display: flex;
  }
  .line {
    width: 9px;
    height: 22px;
    border-radius: 6px;
    background: #409eff;
    margin-right: 11px;
  }
}
.title > div {
  width: 50%;
  margin: 29px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  //   align-items: center;
}
.titleleft {
  display: flex;
  flex-direction: column;
}
/deep/.el-form-item__label {
  width: 100px;
}
/deep/.el-radio__label,
/deep/.el-checkbox__label {
  display: flex;
  align-items: center;
}
</style>
